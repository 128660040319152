import React from 'react';
import styled from 'styled-components';
import map from 'lodash/map';
import Img from 'gatsby-image';

import { featureBlockTypes } from '../types/propTypes';
import mq from '../style/mq';
import { Container, Inner } from './Elements';
import { font, colors } from '../consts/style';

const Wrapper = styled.div`
  background: ${colors.tealDriller};
  padding: 8rem 0;
  overflow: hidden;
  position: relative;
  overflow: hidden;

  ${Inner} {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  .upper-content {
    width: 100%;

    ${mq.tabletSmall_up`
    position: relative;
    background: ${colors.blue};
    padding: 2rem;
    color: white;
    width: 50%;

    &:after {
      content: "";
      height: 100%;
      width: 20rem;
      background: ${colors.blue};
      position: absolute;
      top: 0;
      right: 0;
      transform: translateX(100%);
    }
  `}

    h2 {
      ${font.h2}
      margin-bottom: 1.2rem;

      ${mq.tabletSmall_up`
      position: relative;

      &:before {
        content: "";
        height: .1rem;
        width: calc(100% + 30rem);
        z-index: 1;
        background: white;
        position: absolute;
        bottom: -.6rem;
        left: 0;
      }
    `}
    }
  }
  .upper-image {
    width: 100%;

    ${mq.tabletSmall_up`
    width: 50%;
    position: relative;
    z-index: 5;
    `}
    ${mq.tabletSmall`
      margin: 3.2rem 0;
      transform: translateX(25%);
    `}
  }
  .lower-items {
    display: flex;
    flex-wrap: wrap;
  }
`;

const HeroImage = styled(Img)`
  width: 100%;
`;

const Item = styled.div`
  width: 24%;
  margin-right: 1.3333%;
  margin-top: 4rem;
  padding: 0.8rem;

  &:nth-child(4n) {
    margin-right: 0;
  }

  @media screen and (max-width: 940px) {
    width: 100%;
    margin-right: 0;
  }

  .heading {
    display: flex;
    align-items: center;
    margin-bottom: 1.2rem;
    width: 20rem;
    max-width: 100%;
    img {
      width: 3.2rem;
      margin-right: 1.2rem;
    }
    h3 {
      ${font.h4};
      position: relative;
      top: 0.4rem;
    }
  }
`;

export default function Feature1({ heading, body, image, items }) {
  function renderItems() {
    return map(items, i => (
      <Item key={i.id}>
        <div className="heading">
          <img src={i.icon.url} alt="Icon" />
          <h3>{i.title}</h3>
        </div>
        <div className="body">
          <p>{i.body}</p>
        </div>
      </Item>
    ));
  }

  return (
    <Wrapper>
      <Container>
        <Inner>
          <div className="upper-content">
            <h2>{heading}</h2>
            <p>{body}</p>
          </div>
          <div className="upper-image">
            <HeroImage fluid={image} />
          </div>
          <div className="lower-items">{renderItems()}</div>
        </Inner>
      </Container>
    </Wrapper>
  );
}

Feature1.propTypes = featureBlockTypes;
