import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import PropTypes from 'prop-types';

import mq from '../style/mq';
import { Container, Inner } from './Elements';
import { font } from '../consts/style';

const Wrapper = styled.div`
  background: ${props => props.colorScheme.background};
  color: ${props => props.colorScheme.text};
  padding: 6rem 0;
  overflow: hidden;
  position: relative;

  ${mq.tabletSmall`
    padding: 4rem 0;
  `}

  ${Inner} {
    display: flex;
    align-items: center;
    height: 100%;

    .content {
      width: 50%;
      p {
        ${font.p};
      }
      h2 {
        ${font.heading};
        margin-bottom: 1.2rem;
      }
      ul {
        text-transform: uppercase;
        margin-top: 1.2rem;
        list-style: none;
        padding-left: 2.4rem;

        li {
          position: relative;
          &:before {
            content: '';
            width: 0;
            height: 0;
            border-top: 0.8rem solid transparent;
            border-bottom: 0.8rem solid transparent;
            border-left: 1.2rem solid ${props => props.colorScheme.text};
            position: absolute;
            top: 0.2rem;
            left: -2rem;
            border-radius: 50%;
            ${mq.tabletSmall`
        border-top: 0.6rem solid transparent;
        border-bottom: 0.6rem solid transparent;
        border-left: 1rem solid ${props => props.colorScheme.text};
        top: .3rem;
        left: -1.6rem;
    `}
          }
        }
      }
      ${font.p};
    }
    .image {
      width: 50%;
      height: 40rem;
    }

    ${props =>
      props.inverted &&
      `
    .content {
      order: 1;
    }
    `}

    ${mq.tabletSmall`
      flex-wrap: wrap;
      .content {
        order: 1;
        margin-top: 2rem;
      }
      .content, .image {
        width: 100%;
      }
      .image {
        height: unset;
      }
    `}
  }
`;

const FeatureImage = styled(Img)`
  height: 100%;
  width: 100%;
`;

export default function FeatureBlock({
  title,
  content,
  image,
  inverted,
  colorScheme,
}) {
  return (
    <Wrapper colorScheme={colorScheme} inverted={inverted}>
      <Container>
        <Inner>
          <div className="content">
            <h2>{title}</h2>
            <div dangerouslySetInnerHTML={{ __html: content }}></div>
          </div>
          <div className="image">
            <FeatureImage imgStyle={{ objectFit: 'contain' }} fluid={image} />
          </div>
        </Inner>
      </Container>
    </Wrapper>
  );
}

FeatureBlock.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  image: PropTypes.object.isRequired,
  inverted: PropTypes.bool.isRequired,
  colorScheme: PropTypes.object.isRequired,
};
