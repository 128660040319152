import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from 'styled-components';

import { colors } from '../consts/style';
import mq from '../style/mq';
import SEO from '../components/SEO';
import { PageWrapper } from '../components/Elements';
import Feature1 from '../components/Feature1';
import CTABlock from '../components/CTABlock';
import GenericHero from '../components/GenericHero';
import FeatureBlock from '../components/FeatureBlock';

const CTAWrapper = styled.div`
  display: flex;
  overflow: hidden;
  ${mq.mobile`
      flex-wrap: wrap;
  `}
`;
const Block = styled.div`
  width: 50%;
  background: ${props => props.bg};
  display: flex;
  justify-content: ${props => props.jc};
  ${mq.mobile`
    width: 100%;
  `}
`;

const featuresQuery = graphql`
  {
    drilling: datoCmsDrillingPage {
      heroGif {
        url
      }
      ctaBlockTitle
    }
    exploration: datoCmsExplorationPage {
      heroGif {
        url
      }
      ctaBlockTitle
    }
    features: datoCmsFeaturesPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroHeading
      heroBody
      heroImage {
        fluid(maxWidth: 1800, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      heroMobileImage {
        fluid(maxWidth: 1200, imgixParams: { fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      section1Heading
      section1Body
      section1Image {
        fluid(maxWidth: 1200, imgixParams: { fm: "png", auto: "compress" }) {
          ...GatsbyDatoCmsFluid_noBase64
        }
      }
      section1Items {
        id
        title
        body
        icon {
          url
        }
      }
      featureBlocks {
        id
        title
        content
        image {
          fluid(maxWidth: 1200, imgixParams: { fm: "png", auto: "compress" }) {
            ...GatsbyDatoCmsFluid_noBase64
          }
        }
      }
    }
  }
`;

const blockColorSchemes = [
  { background: colors.dark, text: 'white' },
  { background: colors.tealDriller, text: colors.blue },
  { background: colors.dark, text: 'white' },
  { background: colors.yellow, text: colors.blue },
];

export default function ExplorationPage() {
  const data = useStaticQuery(featuresQuery);
  const {
    seoMetaTags,
    heroHeading,
    heroBody,
    heroImage,
    heroMobileImage,
    section1Heading,
    section1Body,
    section1Image,
    section1Items,
    featureBlocks,
  } = data.features;

  const { drilling, exploration } = data;

  const pageHeroProps = {
    heading: heroHeading,
    body: heroBody,
    image: heroImage.fluid,
    imageMobile: heroMobileImage.fluid,
  };

  const feature1Props = {
    heading: section1Heading,
    body: section1Body,
    image: section1Image.fluid,
    items: section1Items,
  };

  const drillingProps = {
    bg: colors.tealDriller,
    color: colors.dark,
    heading: drilling.ctaBlockTitle,
    slug: '/for-drillers',
    gif: drilling.heroGif.url,
    inverted: true,
    buttonTextColor: colors.tealDriller,
  };

  const explorationProps = {
    bg: colors.grey,
    color: 'white',
    heading: exploration.ctaBlockTitle,
    slug: '/for-explorers',
    gif: exploration.heroGif.url,
    inverted: false,
    buttonTextColor: 'white',
  };

  function renderFeatures() {
    let colorSchemeVal = -1;
    return featureBlocks.map((feature, i) => {
      // Reset the values on multiples of 3
      if (colorSchemeVal === 3) colorSchemeVal = -1;
      colorSchemeVal++;
      const colorScheme = blockColorSchemes[colorSchemeVal];
      const props = {
        ...feature,

        inverted: i % 2 !== 0,
        image: feature.image.fluid,
        colorScheme,
      };
      return <FeatureBlock key={feature.id} {...props} />;
    });
  }

  return (
    <PageWrapper>
      <SEO meta={seoMetaTags} />
      <GenericHero {...pageHeroProps} />
      <Feature1 {...feature1Props} />
      {renderFeatures()}
      <CTAWrapper>
        <Block bg={colors.teal} jc="flex-end">
          <CTABlock {...drillingProps} />
        </Block>
        <Block bg={colors.grey} jc="flex-start">
          <CTABlock {...explorationProps} />
        </Block>
      </CTAWrapper>
    </PageWrapper>
  );
}
