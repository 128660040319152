import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { genericBlockTypes } from '../types/propTypes';
import mq from '../style/mq';
import { Container, Inner } from './Elements';
import { font, colors, boxShadow } from '../consts/style';

const Wrapper = styled.div`
  background: ${props => props.bg};
  height: 43rem;
  width: 59rem;
  max-width: 100%;
  position: relative;
  ${Container} {
    position: relative;
  }
  ${Inner} {
    display: flex;
    align-items: center;
    height: 100%;
    ${props => props.inverted && 'justify-content: flex-end;'}
  }
  ${mq.tabletSmall`
    height: 43rem;
  `}
  ${mq.mobile`
      width: 100%;
      overflow: hidden;
  `}
`;

const Content = styled.div`
  width: 50%;
  color: ${props => props.color};
  position: relative;
  z-index: 10;
  h2 {
    ${font.h2};
    ${mq.tabletSmall`
      ${font.h1};
    `}
  }
  button {
    margin-top: 1.2rem;
    color: ${props => props.buttonTextColor};
    background: ${colors.dark};
    ${boxShadow.default};
    ${font.button};
  }
`;

const HeroImagery = styled.div`
  position: absolute;
  bottom: 0;
  width: 130%;
  z-index: 0;
  pointer-events: none;
  transform: translateX(${props => (props.inverted ? '-40%' : '40%')});
  ${props => (props.inverted ? 'left: 0;' : 'right: 0;')}
  ${mq.tabletSmall`
    width: 200%;
    transform: translateX(${props => (props.inverted ? '-50%' : '50%')});
  `}
`;

const GIF = styled.img`
  width: 100%;
  max-width: 100%;
`;

export default function CTABlock({
  bg,
  color,
  inverted,
  heading,
  gif,
  slug,
  buttonTextColor,
}) {
  return (
    <Wrapper bg={bg} inverted={inverted}>
      <Container>
        <Inner>
          <Content color={color} buttonTextColor={buttonTextColor}>
            <h2>{heading}</h2>
            <Link to={slug}>
              <button>
                <span>More</span>
              </button>
            </Link>
          </Content>
          <HeroImagery inverted={inverted}>
            <GIF src={gif} />
          </HeroImagery>
        </Inner>
      </Container>
    </Wrapper>
  );
}

CTABlock.propTypes = genericBlockTypes;
